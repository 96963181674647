import { graphql } from 'gatsby';
import * as React from 'react';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import { getImage } from 'gatsby-plugin-image';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import Seo from '../components/seo';
import { LandingPageLayout } from '../components/layout';
import { Section } from '../components/layout/Section';
import { UmoPromote } from '../components/UmoPromote';

const PageInstructionCreated = ({ pageContext, data }) => {
  const { bannerImageCall } = data;
  const { t } = useTranslation(['how-to-create-an-instruction']);
  const bannerImage = getImage(bannerImageCall);
  return (
    <>
      <Seo title={t('meta.title')} description={t('meta.description')} lang={pageContext.language} />

      <LandingPageLayout
        bannerImage={bannerImage}
        bannerTitle={t('banner.title')}
        bannerDescription={t('banner.description')}
      >
        <Section heading={t('heading.createInstruction')}>
          <Typography>{t('content.createInstruction')}</Typography>
          <Typography>{t('content.createInstruction2')}</Typography>
          <Typography>{t('content.createInstruction3')}</Typography>
          <Section headingVariant="h3" heading={t('heading.instructionRegel')}>
            <ol>
              {[
                t('content.instructionRegel1'),
                t('content.instructionRegel2'),
                t('content.instructionRegel3'),
                t('content.instructionRegel4'),
                t('content.instructionRegel5'),
                t('content.instructionRegel6'),
                t('content.instructionRegel7'),
                t('content.instructionRegel8'),
              ].map((text) => {
                return (
                  <ListItem style={{ display: 'list-item' }} key={text}>
                    <ListItemText>{text}</ListItemText>
                  </ListItem>
                );
              })}
            </ol>
          </Section>
        </Section>

        <Section heading={t('heading.createPerfekt')}>
          <Typography>{t('content.createPerfekt')}</Typography>

          <Typography>
            <Trans
              t={t}
              components={[
                <Link key="0" to="/personnel-hygiene" />,
                <Link key="1" to="/forklift-drivers-instruction" />,
              ]}
              i18nKey="content.instructionRegel20"
            />
          </Typography>

          <UmoPromote />
        </Section>
      </LandingPageLayout>
    </>
  );
};
export default PageInstructionCreated;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    bannerImageCall: file(relativePath: { eq: "instructionCreated.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
      }
    }
  }
`;
